import HomeImage from "../Images/ProfilePic.jpg";
import fig1 from "../Images/bp1fig1.png";

function Blog_EarlyInvesting() {
  return (
    <main class="mt-12 text-left pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <header class="mb-4 lg:mb-6 not-format">
            <address class="flex items-center mb-6 not-italic">
              <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  class="mr-4 w-16 h-16 rounded-full"
                  src={HomeImage}
                  alt="Jese Leos"
                />
                <div>
                  <a
                    href="https://www.garyfreund.com/about"
                    rel="author"
                    class="text-xl font-bold text-gray-900 dark:text-white"
                  >
                    Gary Freund
                  </a>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    Author, Teacher & Speaker on Personal Finance
                  </p>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    <time
                      pubdate
                      datetime="2022-02-08"
                      title="February 8th, 2022"
                    >
                      Oct. 6, 2024
                    </time>
                  </p>
                </div>
              </div>
            </address>
            <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
              The Power of <font color="#059669">Early Investing</font>: Why
              Your 20s Are Key to a Secure Retirement.
            </h1>
          </header>
          <p class="mt-4">
            A successful retirement rarely happens by chance. It happens by
            design. A wise old man once told me, “if you fail to plan, you plan
            to fail.” You need a plan to have a successful retirement, so let’s
            take a look at the importance of starting early.
          </p>
          <p className="my-4">
            In Figure 1-2, we look at investing $400 per month with a 10.12%
            return on investment. You may be asking why I chose 10.12%. The S&P
            500 comprises the 500 largest publicly traded companies in America.
            When you hear someone ask, “How is the market doing?” One common
            benchmark of “the market” is the S&P 500.
          </p>
          <p className="my-4">
            The average annual return for the S&P 500 from 1926 through 2022 was
            10.12%.<sup>1</sup> This information consists of 96 years of
            historical data. Please don’t get bogged down by the rate of return
            and lose sight of the bigger picture.
          </p>
          <figure>
            <img src={fig1} alt="" />
            <figcaption>Figure 1-2</figcaption>
          </figure>

          <p className="my-4">
            Take a close look at Figure 1-2 at the difference between starting
            at age 25 versus starting at 22. This is an enormous difference from
            starting only three years earlier. Based on this data, why wouldn’t
            everyone start to invest with their very first paycheck right out of
            college? You don’t need to invest a lot of money. You can start with
            $25 or $50 per paycheck.
          </p>
          <p className="my-4">
            For a deeper dive into this topic, check out my book, The Financial
            Compass, where I explore all of your essential day-to-day financial
            decisions. You can find it on Amazon at{" "}
            <a
              className="text-teal-700 font-semibold underline"
              href="https://www.amazon.com/dp/B0D7QW8SPX?ref_=pe_93986420_775043100"
            >
              The Financial Compass
            </a>
            .
          </p>
          <p className="mt-8">
            <sup>1</sup> Booth, David. “Two steps forward, one step back for
            investors.” Firstlinks. Last modified February 22, 2023.{" "}
            <a
              className="text-teal-700 font-semibold underline"
              href="https://www.firstlinks.com.au/two-steps-forward-one-step-back-investors"
            >
              Link
            </a>
          </p>
        </article>
      </div>
    </main>
  );
}

export default Blog_EarlyInvesting;
