import HomeImage from "../Images/ProfilePic.jpg";

function Blog_CarDealerships() {
  return (
    <main class="mt-12 text-left pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <header class="mb-4 lg:mb-6 not-format">
            <address class="flex items-center mb-6 not-italic">
              <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  class="mr-4 w-16 h-16 rounded-full"
                  src={HomeImage}
                  alt="Jese Leos"
                />
                <div>
                  <a
                    href="https://www.garyfreund.com/about"
                    rel="author"
                    class="text-xl font-bold text-gray-900 dark:text-white"
                  >
                    Gary Freund
                  </a>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    Author, Teacher & Speaker on Personal Finance
                  </p>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    <time
                      pubdate
                      datetime="2022-02-08"
                      title="February 8th, 2022"
                    >
                      Oct. 14, 2024
                    </time>
                  </p>
                </div>
              </div>
            </address>
            <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
              How Two Buyers Can Pay Drastically Different Prices at the{" "}
              <font color="#059669">Same</font> Car Dealership
            </h1>
          </header>
          <p class="mt-4">
            The car business is one of the few in which two people can walk into
            the exact same car dealership on the exact same day and pay
            dramatically different amounts of money for the exact same make and
            model. These two people can literally drive their car down the road
            having paid thousands of dollars difference.
          </p>
          <p className="my-4">
            So many people walk into car dealerships without first doing any
            research. They essentially walk in blind without knowing how much
            they should pay for the car. This makes no sense to me, as typically
            a car will be the second-most expensive purchase we make in our
            lifetime other than our homes.
          </p>
          <p className="my-4">
            The best way to get a good deal and not overpay for a car is to do
            your research first. You need to know how much the dealer paid for
            the car if it’s a new car, and how much the car is worth if it’s a
            used car. You also need to know how much your current car is worth
            if you’re trading it in or selling it yourself. Having this
            knowledge will ultimately let you know whether you really “got a
            good deal” or not.
          </p>

          <p className="my-4">
            If you knew you could save $2,000 by doing a little research each
            time you bought a car, would you be interested? Most people I know
            say “yes” to this question. Why? Because it helps you achieve your
            goals faster.
          </p>
          <p className="my-4">
            For a deeper dive into this topic, check out my book, The Financial
            Compass, where I explore all of your essential day-to-day financial
            decisions. You can find it on Amazon at{" "}
            <a
              className="text-teal-700 font-semibold underline"
              href="https://www.amazon.com/dp/B0D7QW8SPX?ref_=pe_93986420_775043100"
            >
              The Financial Compass
            </a>
            .
          </p>
        </article>
      </div>
    </main>
  );
}

export default Blog_CarDealerships;
