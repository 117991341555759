import SpeakingHeader from "../Images/Speaking.jpg";
import SpeakingProfile from "../Images/Speaking_Image.jpg";
import { FaGraduationCap, FaBaby } from "react-icons/fa";
import "./Speaking.css";
import { Slide, Zoom } from "react-awesome-reveal";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "@emailjs/browser";
import { toast, Toaster } from "react-hot-toast";

// const element = document.getElementById("box");

function Speaking() {
  const captchaRef = useRef(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  emailjs.init("Yn_uOM9S0kzuGlT3s");

  async function handleSubmit(e) {
    e.preventDefault();
    const token = captchaRef.current.getValue();

    captchaRef.current.reset();
    // console.log(token.length);
    if (captchaToken) {
    }
    if (token) {
      if (token.length > 1) {
        emailjs
          .sendForm(
            "service_s6wxgzl",
            "template_kpgz7pj",
            e.target,
            "Yn_uOM9S0kzuGlT3s"
          )
          .then(
            (result) => {
              toast.success("Form Submitted");
              setFormSubmitted(true);
            },
            (error) => {
              toast.error("Error submitting form. Please try again later.");
            }
          );
      } else {
        // Toast saying captcha could not be verified
        toast.error("Please complete the Captcha");
      }
    } else {
      toast.error("Please complete the Captcha");
    }
  }

  const verify = () => {
    captchaRef.current.getResponse().then((res) => {
      setCaptchaToken(res);
    });
    toast.success("Form Submitted");
    console.log("test");
  };

  return (
    <div className="flex flex-col justify-center">
      <Toaster position="bottom-center" reverseOrder={false} />
      <div className="mt-24 relative w-full h-80">
        <img
          src={SpeakingHeader}
          alt="Banner"
          className="absolute w-full h-full object-cover blur-sm"
        />
        <div className="absolute w-full h-full flex items-center justify-center">
          <Slide direction="up" duration="600" triggerOnce="true">
            <h1 className="oswald text-white text-8xl font-bold">Speaking</h1>
          </Slide>
        </div>
      </div>

      <div className="mx-auto my-8 flex flex-col md:flex-row gap-y-5 lg:w-4/5 justify-center md:items-center text-left">
        {/* Left section */}
        <div className="md:w-2/3 p-4 space-y-6">
          <h1 className="oswald text-green-500 text-3xl font-bold mb-2">
            BOOK GARY TO SPEAK AT YOUR NEXT EVENT!
          </h1>
          <hr className="border-t border-gray-300 my-2" />
          <p className="font-bold text-lg my-2">
            Passionate. Authentic. Compelling. Transparent. Inspiring.
          </p>
          <p className="text-lg my-2">
            Gary's popular speaking engagements include libraries, universities,
            and conferences. Gary can customize the content and length of his
            presentations to meet the needs of his audiences.
          </p>
          <div>
            <a
              a
              href="#Form"
              className=" oswald bg-green-500 mt-14 text-white font-bold py-2 px-5 rounded text-2xl"
              style={{ scrollBehavior: "smooth" }}
            >
              Book Gary Now
            </a>
          </div>
        </div>

        {/* Right section */}
        <div className="max-w-xl p-4">
          <img src={SpeakingProfile} alt="Gary" className="rounded-lg w-full" />
        </div>
      </div>

      <div className="my-20 relative bg-green-500 text-white p-8 text-center">
        <div className="absolute inset-0 h-full w-full bg-green-500 transform -skew-y-3"></div>
        <div className="relative z-10">
          <Zoom delay="30" duration="800" triggerOnce="true">
            <h1 className="oswald text-center mt-3 text-7xl font-bold">
              Topics
            </h1>
          </Zoom>
          <div className="my-8 p-4 gap-20 flex flex-wrap justify-center p-20 ">
            {/* Tile 1 */}

            <div className="m-w-96 flip-card">
              <div className="flip-card-inner">
                {/*
                 */}
                <div
                  className="flip-card-front flex flex-col items-center justify-center p-8 bg-green-400 rounded-lg shadow-lg"
                  style={{
                    height: "100%",
                    minHeight: "20rem",
                    width: "100%",
                    minWidth: "20rem",
                  }}
                >
                  <FaGraduationCap className="text-white text-9xl mt-5" />
                  <div className="text-center mt-auto">
                    <h2 className="text-4xl font-bold text-white">
                      Financial Steps to Take After Graduation
                    </h2>
                  </div>
                </div>

                <div
                  className="flip-card-back flex flex-col items-center justify-center p-8 bg-green-200 rounded-lg shadow-lg"
                  style={{ height: "100%", minHeight: "20rem" }}
                >
                  <div className="text-center">
                    <h2 className="text-xl font-bold text-green-800">
                      This workshop is essential for anyone starting adulthood.
                      You'll learn to:
                    </h2>
                    <ul className=" mt-2 list-inside text-left space-y-1">
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Cut costs when renting an apartment
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Get great deals on cars
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Save money on home loans, car loans, and insurance costs
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Set goals and create your financial plan
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Retire with financial freedom
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* Tile 2 */}
            <div className="flip-card">
              <div className="flip-card-inner">
                <div
                  className="flip-card-front flex flex-col items-center justify-center p-8 bg-green-400 rounded-lg shadow-lg"
                  style={{ height: "100%", minHeight: "20rem" }}
                >
                  <FaBaby className="text-white text-9xl mt-9" />
                  <div className="text-center mt-auto">
                    <h2 className="text-4xl font-bold text-white">
                      Financial Steps to Take When You Have Children.
                    </h2>
                  </div>
                </div>
                <div
                  className="flip-card-back flex flex-col items-center justify-center p-8 bg-green-200 rounded-lg shadow-lg"
                  style={{ height: "100%", minHeight: "20rem" }}
                >
                  <div className="text-center">
                    <h2 className="text-xl font-bold text-green-800">
                      This workshop is essential for both prospective and
                      current parents. You will learn about the critical
                      decisions involved in:
                    </h2>
                    <ul className=" mt-2 list-inside text-left space-y-2">
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Life insurance
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Creating a last will and testament
                      </li>
                      <li class="flex items-center text-green-600 text-xl">
                        <svg
                          class="w-3.5 h-3.5 me-2 text-green-600 flex-shrink-0"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                        </svg>
                        Funding a college education
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 h-full w-full bg-green-500 transform skew-y-3 bottom-0"></div>
      </div>
      {/* QUOTES */}
      {/* <div class="lg:columns-2 md:columns-2 space-y-10 mx-auto gap-28 lg:max-w-7xl">
        <div class=" text-center text-left">
          <div class="flex justify-center justify-start">
            <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </div>
          </div>
          <blockquote class="mt-2">
            <p class="font-display text-xl font-medium text-slate-900">
              “I was very inspired by Gary's keynote”
            </p>
          </blockquote>
          <figcaption class="mt-2 text-sm text-slate-500">
            <strong class="font-semibold text-green-600 before:content-['—_']">
              John Smith
            </strong>
            , Head of Finance at Company
          </figcaption>
        </div>
        <figure class=" text-center text-left">
          <div class="flex justify-center justify-start">
            <div class="flex gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
                height={18}
                width={20}
              >
                <path
                  fill="#35886f"
                  d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                />
              </svg>
            </div>
          </div>
          <blockquote class="mt-2">
            <p class="font-display text-xl font-medium text-slate-900">
              "Gary's speaking was very insightful”
            </p>
          </blockquote>
          <figcaption class="mt-2 text-sm text-slate-500">
            <strong class="font-semibold text-green-600 before:content-['—_']">
              John Smith
            </strong>
            , Head of Finance at Company
          </figcaption>
        </figure>
      </div> */}
      <br />
      <br />

      <h1 className="oswald text-center mt-3 text-7xl font-bold">Contact</h1>
      {/* Begin Form */}
      <div
        id="Form"
        className="relative isolate px-6 pt-14 lg:px-8 justify-center my-8"
      >
        <div className="max-w-5xl w-full mx-auto">
          {!isFormSubmitted && (
            <form className="space-y-8" onSubmit={handleSubmit}>
              <div className="sm:col-span-3">
                <label
                  htmlFor="country"
                  className="block text-left ml-0.5 text-sm font-medium leading-6 text-gray-900"
                >
                  What would you like me to speak about?
                </label>
                <div className="my-4">
                  <select
                    defaultValue={"Need2Select"}
                    id="contact_reason"
                    name="contact_reason"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 "
                  >
                    <option value="Need2Select" disabled>
                      Select
                    </option>
                    <option
                      value={
                        "Speaking Engagement: Financial Steps to Take After Graduation"
                      }
                    >
                      Financial Steps to Take After Graduation
                    </option>
                    <option
                      value={
                        "Speaking Engagement: Becoming a Parent: Now What?"
                      }
                    >
                      Becoming a Parent: Now What?
                    </option>
                    <option value={"Other"}>Other</option>
                  </select>
                  <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        First name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="first_name"
                          id="first-name"
                          autoComplete="given-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        Last name
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="last_name"
                          id="last-name"
                          autoComplete="family-name"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-left text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="reply_to"
                          type="email"
                          autoComplete="email"
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <hr className="mt-4 h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
                  <div className="mt-6 col-span-full">
                    <label
                      htmlFor="message"
                      className="block text-left text-sm font-medium leading-6 text-gray-900"
                    >
                      Message
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="message"
                        name="message"
                        rows={3}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <ReCAPTCHA
                  sitekey={"6LeIizspAAAAAEvJaIwI7eF04LBv60Q1XoYJV-xc"}
                  ref={captchaRef}
                  onVerify={verify}
                />
              </div>
              <input
                type="submit"
                value="Submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              />
            </form>
          )}
          {isFormSubmitted && (
            <div className="flex justify-center">
              <h2 className="font-semibold leading-7 text-gray-900 text-xl">
                Thank you for submitting the form. I will get back to you soon.
              </h2>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Speaking;
