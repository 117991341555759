import HomeImage from "../Images/ProfilePic.jpg";
import fig21 from "../Images/bp2fig21.png";
import fig22 from "../Images/bp2fig22.png";

function Blog_CreditScore() {
  return (
    <main class="mt-12 text-left pt-8 pb-16 lg:pt-16 lg:pb-24 bg-white dark:bg-gray-900 antialiased">
      <div class="flex justify-between px-4 mx-auto max-w-screen-xl ">
        <article class="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue dark:format-invert">
          <header class="mb-4 lg:mb-6 not-format">
            <address class="flex items-center mb-6 not-italic">
              <div class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white">
                <img
                  class="mr-4 w-16 h-16 rounded-full"
                  src={HomeImage}
                  alt="Jese Leos"
                />
                <div>
                  <a
                    href="https://www.garyfreund.com/about"
                    rel="author"
                    class="text-xl font-bold text-gray-900 dark:text-white"
                  >
                    Gary Freund
                  </a>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    Author, Teacher & Speaker on Personal Finance
                  </p>
                  <p class="text-base text-gray-500 dark:text-gray-400">
                    <time
                      pubdate
                      datetime="2022-02-08"
                      title="February 8th, 2022"
                    >
                      Oct. 12, 2024
                    </time>
                  </p>
                </div>
              </div>
            </address>
            <h1 class="mb-4 text-3xl font-extrabold leading-tight text-gray-900 lg:mb-6 lg:text-4xl dark:text-white">
              The Real Impact of Your <font color="#059669">Credit Score</font>:
              Why It Matters More Than You Think.
            </h1>
          </header>
          <p class="mt-4">
            Are you a trust fund baby? In other words, do you have so much money
            in your 20s and 30s that you don’t need to borrow any money or take
            out a loan for the rest of your life? Can you pay cash for your home
            and cars?
          </p>
          <p className="my-4">
            Almost all of us answer “no” to these questions. As a result, we all
            have to borrow money throughout our lives. At the very least, we
            need to borrow money to buy a home or a car. The obvious question
            is: What happens when you don’t establish and maintain good credit?
          </p>
          <p className="my-4">
            To start with, you will pay higher interest rates when you purchase
            a home. A friend of mine has been a mortgage broker for more than 35
            years. He gave me the data in Figure 2-1, which represents $250,000
            borrowed on a 30-year fixed home loan.
          </p>
          <figure>
            <img src={fig21} alt="" />
            <figcaption>Figure 2-1</figcaption>
          </figure>

          <p className="my-4">
            In figure 2-1, you learn that 40 or 50 points on our credit score
            plays a significant role in the interest rate you’d be able to get
            when purchasing a home. Would you rather pay $387,014.37 or
            $253,974.11 in interest for your home? This $133,040.26 difference
            is all based on our credit score. Can you think of other ways you’d
            rather spend $133,040.26?
          </p>
          <p className="my-4">
            Having a low credit score also means you’ll pay higher interest
            rates when you purchase a car. Another friend of mine is a general
            manager for an automobile dealership, and he’s been in the car
            business for more than 30 years. The following data in Figure 2-2 is
            from one of the banks his dealership works with and is based on
            borrowing $20,000 on a used car that is 2017 or newer with a loan
            term of 48 months (four years).
          </p>
          <figure>
            <img src={fig22} alt="" />
            <figcaption>Figure 2-2</figcaption>
          </figure>
          <p className="my-4">
            Similar to the home loan, why would you want to have a $533.48
            monthly car payment when you can have a $451.58 monthly car payment
            simply by maintaining a good credit score? The difference in total
            interest paid for this car example is $3,931.05. I come back to the
            same question I asked on your home loan. Can you think of other ways
            to spend $3,931.05 instead of giving a bank more money?
          </p>
          <p className="my-4">
            For a deeper dive into this topic, check out my book, The Financial
            Compass, where I explore all of your essential day-to-day financial
            decisions. You can find it on Amazon at{" "}
            <a
              className="text-teal-700 font-semibold underline"
              href="https://www.amazon.com/dp/B0D7QW8SPX?ref_=pe_93986420_775043100"
            >
              The Financial Compass
            </a>
            .
          </p>
        </article>
      </div>
    </main>
  );
}

export default Blog_CreditScore;
